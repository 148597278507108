import CalendarGrid from './CalendarGrid';
import CalendarHeader from './CalendarHeader';

/**
 *
 * @component
 * @param {object} wrapping object
 * @param {Function}\
 * @param {Integer} monthTracker 0 | 1 representing current or next month
 * @param {callback}
 * @param {therapist}
 * @param {Integer} selectedDay the value representing the selected day by user
 * @returns
 */
function CalendarRenderer({
  onDayClick,
  monthTracker,
  calMan,
  callback,
  therapist,
  selectedDay,
}) {
  let currentMonthDays = calMan.getCalendarDays();
  let fillerDays = calMan.calcFillerDays();

  return (
    <div className="calendar container-padding secondary-bcg">
      <CalendarHeader
        monthName={calMan.getMonthName()}
        monthTracker={monthTracker}
        callback={callback}
        selectedDay={selectedDay}
      ></CalendarHeader>
      <CalendarGrid
        fillerDays={fillerDays}
        currentMonthDays={currentMonthDays}
        callback={onDayClick}
        therapist={therapist}
        calMan={calMan}
        selectedDay={selectedDay}
      ></CalendarGrid>
    </div>
  );
}

export default CalendarRenderer;
