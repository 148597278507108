import React, { useState, useEffect } from 'react';
import { ProductLister } from '../templates/FormComponents/OptionLister';
import { useNavigate } from 'react-router-dom';
import { getAllUserSelections } from '../utils/persistence/dataPersistence';
import CheckboxWithLabel from '../components/inputFields/CheckboxWithLabel';
import { questionaireURL } from '../api/urls';
import CustomHero from '../components/common/CustomHero.jsx';
import NavBar from '../components/common/NavBar.jsx';
import PaymentOption from '../components/reviewPage/PaymentOption.jsx';
import { pstdayjs } from '../utils/calendar/calendarTools.js';
import Details from '../components/reviewPage/Details.jsx';
import PriceBreakdown from '../components/reviewPage/PriceBreakdown.jsx';
import NavigationButton from '../components/common/NavigationButton.jsx';
import { ListItemMT, ListItemSO } from '../snippets/ListItem.jsx';
import { checkReservation } from '../utils/tools/timerSetup.js';
import CountdownInitializer from '../components/common/CountdownInitializer.jsx';
import {
  locationSchema,
  reservationBodySchema,
  verifySessionData,
} from '../utils/validators/schemas.js';

function objectToQueryString(obj) {
  const flatten = (obj, prefix = '') => {
    return Object.keys(obj).reduce((acc, key) => {
      const pre = prefix.length ? `${prefix}[${key}]` : key;
      const value = obj[key];

      if (Array.isArray(value)) {
        value.forEach((v, i) => {
          acc.push(`${pre}[${i}]=${encodeURIComponent(v)}`);
        });
      } else if (typeof value === 'object' && value !== null) {
        acc.push(...flatten(value, pre));
      } else {
        acc.push(`${pre}=${encodeURIComponent(value)}`);
      }

      return acc;
    }, []);
  };

  return flatten(obj).join('&');
}

const ServiceOverview = ({ nextPath, prevPath, verify }) => {
  const [servicesData, setServicesData] = useState([]);
  const [serviceSubtotal, setSubtotal] = useState();
  const [depositAmount, setDeposit] = useState(50);

  const [appointmentDate, setAppointmentDate] = useState('');

  const [location, setLocation] = useState(undefined);
  const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
  const [expirationTime, setExpirationTime] = useState(null);

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(nextPath);
  };
  const handlePrevPath = () => {
    navigate(prevPath);
  };

  useEffect(() => {
    verify(navigate);
    verifySessionData(
      'userAddress',
      locationSchema,
      navigate,
      '/set-your-place'
    );
    verifySessionData(
      'reservationInfo',
      reservationBodySchema,
      navigate,
      '/set-your-time'
    );

    const fetchData = async () => {
      const data = getAllUserSelections();

      const queryString = objectToQueryString(data);
      const getRequestUrl = `${questionaireURL}/selection-data?${queryString}`;

      try {
        const response = await fetch(getRequestUrl, {
          headers: {
            Accept: 'application/json',
          },
        });

        const text = await response.text(); // Get raw response text
        // console.log('Raw response:', text); // Log the raw response

        // Attempt to parse JSON only if response is not empty and is valid JSON
        const rawServiceData = text ? JSON.parse(text) : {};
        // console.log(data.selectedChoices);

        setServicesData(rawServiceData.selectedChoices);
        setLocation(data.userAddress);
        setSubtotal(rawServiceData.totalCost);
        setDeposit(rawServiceData.depositAmount);
        setAppointmentDate(data.date);
      } catch (error) {
        console.error('Failed to retrieve user selections:', error);
      }
    };

    fetchData();
    checkReservation(setExpirationTime);
  }, []);

  // console.log('serviceSubtotal', serviceSubtotal);
  // console.log('deposit', depositAmount);
  // console.log('appointmentDate', appointmentDate);
  // console.log('servicesData[2][0]', servicesData[2]);
  // console.log(servicesData[1]);

  return (
    <div id="review-page full-height" className="flex-column-direction ">
      {depositAmount &&
      serviceSubtotal &&
      appointmentDate &&
      location &&
      servicesData.length > 0 ? (
        <>
          {' '}
          <NavBar callback={handlePrevPath}></NavBar>
          <CustomHero
            firstLine="Appointment"
            secondLine="Review"
            subheading="Review your appointment details."
          ></CustomHero>
          <div className=" standard-max-width center-margin ">
            <CountdownInitializer expirationTime={expirationTime} />
            {/* <ProductLister data={servicesData} /> */}
            <section className=" container-padding container-radius secondary-bcg flex-column-direction flex-column-container-gap ">
              <div className="list-item-wrapper ">
                <h3>Your Appointment Details</h3>
                <ListItemSO
                  title={servicesData[0][0].title}
                  id={servicesData[0][0].id}
                  imgSrc={servicesData[0][0].imgUrl}
                  specialData={[servicesData[2], servicesData[1]]}
                  // showStartingAtText={false}
                  // description={servicesData[0][0].description}
                  // descBehavior="truncate"
                ></ListItemSO>
              </div>
              <hr className="clear-border flex-column-self-center " />
              <Details
                date={pstdayjs(appointmentDate).format('dddd, MMMM D, YYYY')}
                time={pstdayjs(appointmentDate).format('h:mm a')}
                location={location}
              ></Details>
              <hr className="clear-border flex-column-self-center " />
              <PaymentOption
                total={serviceSubtotal}
                deposit={depositAmount}
                appointmentDate={pstdayjs(appointmentDate).format('MMM D')}
              ></PaymentOption>

              <span
                onClick={(e) => setShowPriceBreakdown(true)}
                className="bold normal-text-light zero-right-margin underline pointer-cursor"
              >
                price breakdown
              </span>
            </section>
            {/* <CheckboxWithLabel label="By continuing your waive liability" /> */}
            {/* <button onClick={handleNavigation}>Continue to Checkout</button> */}

            <PriceBreakdown
              massageType={servicesData[0][0]}
              massageDuration={servicesData[1][0]}
              addons={servicesData[2] || []}
              showPriceBreakdown={showPriceBreakdown}
              setShowPriceBreakdown={setShowPriceBreakdown}
            ></PriceBreakdown>
            <section className="section-nav text-align-center">
              <NavigationButton
                description={'Continue to Checkout'}
                nextPageLink={handleNavigation}
              />
            </section>
          </div>
          {showPriceBreakdown && (
            <div
              id="overlay"
              className=" absolute-position height-100 width-100"
              onClick={(e) => setShowPriceBreakdown(false)}
            ></div>
          )}
        </>
      ) : (
        <h1></h1>
      )}
    </div>
  );
};

export default ServiceOverview;
