import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckoutForm from '../components/depositCollection/checkoutForm';
import { getAllUserSelections } from '../utils/persistence/dataPersistence';
import NavBar from '../components/common/NavBar';
import {
  locationSchema,
  reservationBodySchema,
  verifySessionData,
} from '../utils/validators/schemas.js';

const CollectDeposit = ({ nextPath, verify }) => {
  const data = getAllUserSelections();
  const navigate = useNavigate();

  // const navigate = useNavigate();
  // const handleNavigation = () => {
  //   navigate(nextPath);
  // };

  useEffect(() => {
    verify(navigate);
    verifySessionData(
      'userAddress',
      locationSchema,
      navigate,
      '/set-your-place'
    );
    verifySessionData(
      'reservationInfo',
      reservationBodySchema,
      navigate,
      '/set-your-time'
    );
  });
  return (
    <div>
      <NavBar />
      {/* <CheckboxWithLabel label="By continuing you accept our terms of service" /> */}
      <CheckoutForm userSelections={data} />
    </div>
  );
};

export default CollectDeposit;
