import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData.js';
import isBetween from 'dayjs/plugin/isBetween'; // ES 2015
import duration from 'dayjs/plugin/duration.js';

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localeData);
dayjs.extend(isBetween);
let date = new Date();
let numDaysInFeb = date.getFullYear() % 4 === 0 ? 29 : 28;
export const dayMap = new Map();

dayMap.set('January', 31);
dayMap.set('February', numDaysInFeb);
dayMap.set('March', 31);
dayMap.set('April', 30);
dayMap.set('May', 31);
dayMap.set('June', 30);
dayMap.set('July', 31);
dayMap.set('August', 31);
dayMap.set('September', 30);
dayMap.set('October', 31);
dayMap.set('November', 30);
dayMap.set('December', 31);

/**
 * Returns the name of the viewingDate month.
 * @param {dayjs} date object which will be used to get month number
 * @returns {String} String name of a month of viewingDate ex. "June"
 */
export function getMonthName(date) {
  return dayjs.months()[date.month()];
}

/**
 * tsrange array from postgres wraps the arrays in quotes,
 * this method removes the quotes wrapping the arrays.
 *
 * @param {*} object object array containing tsrange string arrays from the database
 */
export function parseTSRANGE(object) {
  let properties = Object.keys(object);
  let newObj = {};
  properties.forEach((prop) => {
    newObj[prop] = JSON.parse(object[prop]);
  });
  return newObj;
}
const UTCOFFSET = dayjs().tz('America/Los_Angeles').utcOffset();

export const pstdayjs = (time = null, keepLocalTime = false) => {
  if (time == null) {
    return dayjs().utc().utcOffset(UTCOFFSET, keepLocalTime);
  } else return dayjs(time).utc().utcOffset(UTCOFFSET, keepLocalTime);
};
const NYUTCOFFSET = dayjs().tz('America/New_York').utcOffset();
export const nytdayjs = (time = null) => {
  if (time == null) {
    return dayjs().utc().utcOffset(NYUTCOFFSET);
  } else return dayjs(time).utc().utcOffset(NYUTCOFFSET);
};

/**
 * converts AM/PM time string to an object containing hours and minutes variables.
 * Ex. 12:30 pm => {12, 30}
 *
 * @param {String} time english representation of time ex. "12:30 pm"
 * @returns {Object} {hours,minutes}
 */
export const convertAmPmToHoursMins = (stringTime) => {
  // 12:30 pm
  let arr = stringTime.split(' ');
  let time = arr[0].split(':');
  let minutes = parseInt(time[1]);
  let hours = parseInt(time[0]) % 12;

  if (arr[1].toLowerCase() === 'pm' && hours < 12) {
    hours += 12;
  }
  return { hours, minutes };
};
