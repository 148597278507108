export const API_SERVER_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://book.empoweredmovementtherapy.com/json'
    : process.env.REACT_APP_API_SERVER_URL;
    
export const THERAPIST_ID = 3;

export const PAYMENT_VENDOR_KEY =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_PROD_STRIPE_TOKEN
    : process.env.REACT_APP_DEV_STRIPE_TOKEN;
