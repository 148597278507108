// import fs from 'fs';

// Import environment variables
// import dotenv from 'dotenv';
// dotenv.config();

// Read and parse the configuration file
// const config = JSON.parse(fs.readFileSync(configPath, 'utf8'));

// Define the questionnaireInterpreter function
// const questionnaireInterpreter = (data) => {
//   return data.Options.map((option) => {
//     return {
//       optionId: option.id,
//       choiceIds: option.choices.map((choice) => choice.id),
//       multiSelectable: option.settings.multi_select,
//     };
//   });
// };

class QuestionnaireInterpreter {
  constructor(configJSONData) {
    this.configJSONData = configJSONData;
  }
  /**
   * Returns the choices array of a config object found in option_definitions.
   * Ex. it will return [{id:"swedish,...,imgUrl:massage_type_images/swedish-colorized.jpg}, ...,  {
                    "id": "sports",
                    "imgUrl": "massage_type_images/sports_massage-colorized.jpg"
                }]
   * @param {String} typeId massage_type, add_ons, massage_duration, etc.
   */
  getOptionObjectChoices(typeId) {
    let [optionObject] = this.configJSONData.Options.filter(
      (optionObject) => optionObject.id === typeId
    );

    return optionObject.choices;
  }
  /**
   * Gets all choice ids from  a type (massage_type | add_ons | etc) from option_definitions
   * and returns all them in an array
   * @param {String} typeId  massage_type | add_ons | massage_duration | etc.
   * @returns An array of strings representing choices. Ex: [relaxation,therapeutic,sports]
   */
  getAllObjectIdsForType(typeId) {
    let typeObjects = this.getOptionObjectChoices(typeId);
    return typeObjects.map((choice) => choice.id);
  }
}
// Read and parse the configuration file
export default QuestionnaireInterpreter;
