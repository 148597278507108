import { isReservationValidAndRefresh } from '../../api/fetchRequests/appointmentRequests.js';
import {
  deleteUserSelection,
  getUserSelection,
  saveSensitiveUserData,
} from '../persistence/dataPersistence.js';

/**
 * Checks local storage for reservation details.
 * if there is a reservation then this will check if the reservation
 * is still valid with an api call to the database. Then Updates
 * expiration time state with the setExpirationTime function.
 * @param {Function} setExpirationTime state setting function to set reservation expiration time
 * @returns
 */
export const checkReservation = async (setExpirationTime) => {
  const data = await getUserSelection('reservationInfo');

  if (data === null) return;
  let reservationInfo = await isReservationValidAndRefresh(data.appointment_id);

  if (reservationInfo.error || reservationInfo.length === 0) {
    deleteUserSelection('reservationInfo');
    return;
  }
  await saveSensitiveUserData(reservationInfo[0], 'reservationInfo');
  setExpirationTime(reservationInfo[0].expires);
};
