import React, { useState } from 'react';
import './Theme/App.css';
import './Theme/EMTTheme.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Questionnaire from './routes/Questionaire/routeSetter';
import useOptions from './utils/questionaireLoader';
import AppointmentDetails from './routes/AppointmentDetails';
import CollectDeposit from './routes/DepositCollection';
import ConfirmationRouter from './routes/FinalConfirmation';
import ServiceOverview from './routes/ServiceAgreement';
import LocationDetails from './routes/LocationDetails.js';
import ContactForm from './routes/meta/ContactUs';
import PrivacyPolicy from './routes/meta/PrivacyPolicy';
import z from 'zod';
import QuestionnaireInterpreter from './utils/parsers/questionnaireInterpreter.js';
import { convertToLowerCase } from './utils/tools/tools.js';
import { validQuestionnaireData } from './utils/validators/schemas.js';

const route1 = '/';
const route2a = '/set-your-place';
const route2b = '/set-your-time';
const route3 = '/waives-docs';
const route4 = '/make-it-official';
const route5 = '/confirmation';

const contactPage = '/contact-us';
const privacyPolicy = '/privacy-policy';

const App = () => {
  console.log('EMT: 0.2.1');
  // Using custom hook to load configuration data
  const { config, loading, error } = useOptions();

  // Authentication state (currently hardcoded to false)
  const isAuthenticated = false;
  // Show a loading message while the configuration is being loaded
  if (loading) {
    return <div>Loading...</div>;
  }

  // Show an error message if there was an error loading the configuration
  if (error) {
    return <div>Fatal error: {error.message}</div>;
  }

  // questionnaireSelectionSchema & questionnaireInterpreter are the main problems with rerouting
  // because questionnaireInterpreter relies on the config file. And we can't get that config
  // file without making another request.
  // If you can't figure anything out its not a big deal. I was thinking of moving questionnaireInterpreter
  // & questionnaireSelectionSchema inside of useOptions() to get something like this:
  // const { config, loading, error, questionnaireSelectionSchema } = useOptions();

  // Just to clean up App.js a little
  let questionnaireInterpreter = new QuestionnaireInterpreter(config);
  const questionnaireSelectionSchema = z.object({
    massage_type: z.preprocess((array) => {
      return convertToLowerCase(array);
    }, z.enum(questionnaireInterpreter.getAllObjectIdsForType('massage_type')).array().max(1)),
    massage_duration: z.preprocess((array) => {
      return convertToLowerCase(array);
    }, z.enum(questionnaireInterpreter.getAllObjectIdsForType('massage_duration')).array().max(1)),
    add_ons: z
      .preprocess((array) => {
        return convertToLowerCase(array);
      }, z.enum(questionnaireInterpreter.getAllObjectIdsForType('add_ons')).array())
      .optional(),
    flags: z
      .preprocess((array) => {
        return convertToLowerCase(array);
      }, z.enum(['underage', 'pregnant', 'other']).array())
      .optional(),
  });

  const verifyQuestionnaire = (nav) => {
    if (
      !validQuestionnaireData(
        'questionnaire_selections',
        questionnaireSelectionSchema
      )
    )
      nav('/');
  };
  // Main app content
  return (
    <Router basename="/">
      <Routes>
        <Route
          path={route1}
          element={
            <Questionnaire
              auth={isAuthenticated}
              config={config}
              nextPath={route2a}
            />
          }
        />
        <Route
          path={route2a}
          element={
            <LocationDetails
              nextPath={route2b}
              prevPath={route1}
              verify={verifyQuestionnaire}
            />
          }
        />
        <Route
          path={route2b}
          element={
            <AppointmentDetails
              nextPath={route3}
              prevPath={route2a}
              verify={verifyQuestionnaire}
            />
          }
        />
        <Route
          path={route3}
          element={
            <ServiceOverview
              nextPath={route4}
              prevPath={route2b}
              verify={verifyQuestionnaire}
            />
          }
        />
        <Route
          path={route4}
          element={
            <CollectDeposit nextPath={route5} verify={verifyQuestionnaire} />
          }
        />

        <Route
          path={route5}
          element={<ConfirmationRouter nextPath={route1} />}
        />

        <Route path={contactPage} element={<ContactForm />} />
        <Route path={privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={'/terms-of-service'} element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
};

export default App;
