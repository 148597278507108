//TODO: In save functions, swap data and id so that id/key to be in accordance with conventional key/value pair practices.
//TODO: getAllUserSelections() breaks when dealing with an undefined cookie.

/**
 * Enum type used in conjunction with data persistent methods.
 * Example: saveSensitiveUserData("Example",dataNames.CITY).
 * Just used for clarity and autocomplete.
 * let me know what you think of this.
 */
export const dataNames = Object.freeze({
  DATE: 'date',
  CITY: 'city',
  STREET: 'street',
  ZIPCODE: 'zipcode',
});

export const saveQuestionnaireSelection = async (selection, id) => {
  try {
    // Retrieve the existing selections
    let questionnaireSelections =
      JSON.parse(sessionStorage.getItem('questionnaire_selections')) || {};

    // Update the selection for the given ID
    questionnaireSelections[id] = selection;

    // Save the updated selections back to sessionStorage
    sessionStorage.setItem(
      'questionnaire_selections',
      JSON.stringify(questionnaireSelections)
    );
  } catch (error) {
    console.error(`Failed to save selection for ID: ${id}`, error);
  }
};

/**
 * Save user selection to the session or database.
 * @param {Object} selection - The user's selection data.
 * @returns {Promise}
 */
export const saveUserSelection = async (selection, id) => {
  sessionStorage.setItem(id, JSON.stringify(selection));
};
export const deleteUserSelection = async (id) => {
  sessionStorage.removeItem(id);
};
/**
 * @param {string} userId - The user's unique identifier.
 * @returns {Promise<Object>} - The user's selection data.
 */
export const getUserSelection = async (id) => {
  const selection = sessionStorage.getItem(id);
  return selection ? JSON.parse(selection) : null;
};

/**
 * Save sensitive user data securely.
 * @param {Object} userData - The user's sensitive data.
 * @returns {Promise}
 */
export const saveSensitiveUserData = async (userData, id) => {
  // return fetch('/api/save-sensitive-data', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify(userData)
  //   });
  sessionStorage.setItem(id, JSON.stringify(userData));
};

/**
 * Retrieve sensitive user data.
 * @param {string} userId - The user's unique identifier.
 * @returns {Promise<Object>} - The user's sensitive data.
 */
export const getSensitiveUserData = async (userId) => {
  // return fetch(`/api/get-sensitive-data/${userId}`)
  // .then(response => response.json());
};

/**
 * Retrieve all user selections from the sessionStorage.
 * @returns {Object} - An object containing all user selections.
 */
export const getAllUserSelections = () => {
  let allSelections = {};
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    // console.log('key', key);
    if (key) {
      const value = sessionStorage.getItem(key);
      // console.log('value', value);

      allSelections[key] = value ? JSON.parse(value) : null;
    }
  }
  return allSelections;
};
