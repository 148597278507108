import React from 'react';
import NavBar from '../../components/common/NavBar.jsx';
import CustomHero from '../../components/common/CustomHero.jsx';

const PrivacyPolicy = () => {
  return (
    <>
      <NavBar />
      <CustomHero firstLine="Our Privacy Policy" />
      <div className="container-padding secondary-bcg container-radius flex-column-direction flex-column-container-gap">
        <div>
          <h3>1. Information We Collect:</h3>
          <p>
            We collect personal information that you provide when booking
            services, contacting us, or filling out forms on our website. This
            may include your name, email address, phone number, and
            health-related information relevant to our services.
          </p>
        </div>
        <div>
          <h3>2. How We Use Your Information:</h3>
          <p>
            Your information is used solely for providing services, responding
            to inquiries, and tailoring treatments to your needs. We do not
            share, sell, or rent your personal data to third parties.
          </p>
        </div>
        <div>
          <h3>3. Data Security:</h3>
          <p>
            We implement industry-standard security measures to protect your
            personal information from unauthorized access, disclosure, or
            misuse.
          </p>
        </div>

        <div>
          <h3>4. Third-Party Services:</h3>
          <p>
            We may use third-party platforms for processing payments or
            scheduling appointments. These providers have their own privacy
            policies, and we recommend reviewing them.
          </p>
        </div>
        <div>
          <h3>5. Your Consent:</h3>
          <p>
            By using our services or submitting personal information, you
            consent to our privacy practices.
          </p>
        </div>
        <div>
          <h3>6. Contact Us:</h3>
          <p>
            If you have any questions about this policy or how your data is
            handled, please contact us at{' '}
            <a
              href="mailto:info@empoweredmovementtherapy.com"
              style={linkStyle}
            >
              info@empoweredmovementtherapy.com
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};

// const policyContainerStyle = {
//   maxWidth: '600px',
//   margin: '0 auto',
//   padding: '20px',
//   fontFamily: 'Arial, sans-serif',
// };

// const headingStyle = {
//   fontSize: '18px',
//   fontWeight: 'bold',
//   marginTop: '20px',
// };

// const textStyle = {
//   fontSize: '16px',
//   lineHeight: '1.6',
// };

const linkStyle = {
  color: '#007bff',
  textDecoration: 'none',
};

export default PrivacyPolicy;
