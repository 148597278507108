import dayjs, { Dayjs } from 'dayjs';
import Schedule from './Schedule.js';
import { pstdayjs } from '../../../utils/calendar/calendarTools.js';
/**
 * The point of this class is to return
 * which days are available and not available
 * given many schedules for a single therapists
 */
export default class ScheduleManager {
  constructor() {
    this.map = new Map(); //keys === "YYYY-MM-DD YYYY-MM-DD"
    this.LENGTH_OF_TIME_SLOTS = 30;
  }

  /**
   * Adds a Schedule to the ScheduleManager map where the key is
   * the begging and endDate.
   * @example: key: "2025-01-20 2025-02-10"
   * @param {Object} scheduleAvailability
   * @param {Object} scheduleBlockedDates
   * @param {Object} scheduleBlockedTimes
   */
  addSchedule(scheduleAvailability, scheduleBlockedDates) {
    let schedule = new Schedule(
      scheduleAvailability,
      scheduleBlockedDates,
      this.LENGTH_OF_TIME_SLOTS
    );
    this.map.set(`${schedule.startDate} ${schedule.endDate}`, schedule);
  }
  /**
   * returns a Schedule object by using the date object passed
   * as a key.
   * @example: date == 2025-02-10
   * Schedule range == "2025-02-10 2025-02-28"
   * Since the date falls withing the schedule range
   * the specific schedule will be returned.
   * It will check all schedules until one or none match.
   * @param {Dayjs} date
   * @returns {Schedule}
   */
  #getScheduleWithDate(date) {
    let keys = Array.from(this.map.keys());
    let schedule = null;
    keys.forEach((key) => {
      let split = key.split(' ');
      let startDate = pstdayjs(split[0], true).startOf('D');
      let endDate = pstdayjs(split[1], true).endOf('D');

      if (date.isAfter(startDate) && date.isBefore(endDate))
        schedule = this.map.get(key);
    });

    return schedule;
  }

  /**
   * Gets the times slots to display for a given day.
   * @example: user selects 2025-01-24.
   * Then available times could be == [10:30 am, 11:00 am,11:30am,...,5:00pm] and so on.
   * @param {Dayjs} date
   * @param {Integer} appointmentTimeLength
   * @returns
   */
  getOpenTimesForDay(date, appointmentTimeLength) {
    let schedule = this.#getScheduleWithDate(date);
    if (schedule === null) return [];

    return schedule.getOpenTimesForDay(date, appointmentTimeLength);
  }

  /**
   * Returns the integer value representing the next day from
   * today that a therapist can work withing the current month of
   * date.
   * @example: today === 2025-01-10
   * therapist can't work 2025-01-11
   * therapist can work 2025-01-12
   * so it will return 12 for the day
   * @param {*} date
   * @returns
   */
  getSoonestAvailableWorkday(date) {
    // I should look through all schedules not just one
    // Example Schedule A,B,C . If A & B are filled then C
    // should have the next available schedules.
    // TODO: TEST BELOW
    let min = 500;
    let keys = Array.from(this.map.keys());

    keys.forEach((key) => {
      let value = this.map.get(key).getSoonestAvailableWorkday(date);
      if (value < min && value !== -1) min = value;
    });

    if (min === 500) return -1;

    return min;
  }
  checkIfCanWorkThisDay(date) {
    let schedule = this.#getScheduleWithDate(date);
    if (schedule === null) return false;

    return schedule.checkIfCanWorkThisDay(date);
  }
  /**
   * Maps appointments to a schedule. Assumes the appointments
   * are filtered by therapists ID already.
   * @param {Object[]} appointments
   */
  mapBookedAppointmentsToSchedules(appointments) {
    let appointmentMap = new Map();

    // Aggregating all appointments to arrays that
    // where an array represents a time range
    appointments.forEach((appointment) => {
      let schedule = this.#getScheduleWithDate(
        pstdayjs(appointment.appointmentTime)
      );
      if (!schedule) return;
      // Do have a schedule
      if (appointmentMap.has(`${schedule.startDate} ${schedule.endDate}`))
        appointmentMap
          .get(`${schedule.startDate} ${schedule.endDate}`)
          .push(appointment);
      else {
        appointmentMap.set(`${schedule.startDate} ${schedule.endDate}`, [
          appointment,
        ]);
      }
    });
    let keys = Array.from(appointmentMap.keys());

    // Key should be format = "2024-11-20 2024-11-30"
    keys.forEach((key) => {
      let schedule = this.map.get(key);
      schedule.mapAppointments(appointmentMap.get(key));
    });
  }
}
