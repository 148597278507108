import CalendarForm from '../CalendarForm.jsx';
import { pstdayjs } from '../../../utils/calendar/calendarTools.js';
import ScheduleManager from '../objects/ScheduleManager.js';
import CalendarRenderer from '../calendar/CalendarRenderer.jsx';
import CalendarManager from '../calendar/CalendarManager.js';
import TimeContainer from '../timeSelection/TimesContainer.jsx';
import NavigationButton from '../../common/NavigationButton.jsx';
function CalendarStub({}) {
  let beginningOfMonth = pstdayjs();
  let beginningOfMonthString = beginningOfMonth.format('YYYY-MM-DD');
  let endOfMonth = beginningOfMonth.endOf('M');
  let endOfMonthString = endOfMonth.format('YYYY-MM-DD');

  let schedule = {
    sunday: null,
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    maxAppsPerDay: 1,
    timeRange: [beginningOfMonthString, endOfMonthString],
  };
  let scheduleManager = new ScheduleManager();
  scheduleManager.addSchedule(schedule);
  let calManager = new CalendarManager(pstdayjs().toDate());
  return (
    <>
      <div className="flex-column-container-gap calendar-form section__fill-remainder">
        <CalendarRenderer
          calMan={calManager}
          therapist={scheduleManager}
          selectedDay={pstdayjs().date()}
        ></CalendarRenderer>
        <TimeContainer
          activeIndex={'0'}
          canWorkToday={true}
          timesToDisplay={['11:00 pm', '12:00 pm']}
        ></TimeContainer>
      </div>
      <NavigationButton
        description="Confirm Time"
        isDisabled={true}
      ></NavigationButton>
    </>
  );
}
export default CalendarStub;
