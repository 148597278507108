import { objectToQueryString } from './objectToQueryString';
import { questionaireURL } from '../../api/urls';

/**
 * Returns 60 | 90 | 120 integer based on value passed in. Value should come in from
 * session storage.
 *
 * @param {Array[String]} data The array representing massage_duration from session storage
 * @returns {INTEGER}   60 | 90 | 120
 */
export const mapAppointmentLength = async (data) => {
  try {
    const duration = await getServiceDuration(data);
    // console.log('Duration is:', duration);
    return duration;
  } catch (error) {
    console.error('Error mapping appointment length:', error);
    throw error;
  }
};

const getServiceDuration = async (userSelections) => {
  try {
    const queryString = objectToQueryString(userSelections);
    const response = await fetch(
      `${questionaireURL}/selection-duration?${queryString}`
    );
    const responseText = await response.text();
    const responseData = JSON.parse(responseText);

    // console.log("DURATION LOADED FROM BACKEND: ", responseData.serviceDuration);
    return responseData.serviceDuration;
  } catch (error) {
    console.error('Error fetching service duration:', error);
    return 0;
  }
};

/**
 *  Checks if previous pages were completed properly by looking
 *  at sessionStorage for necessary data for this page.
 *  This function should run on each page load.
 * @example if a user wants to be the calendar page, then they should have
 * questionnaire_selections and userAddress in sessionStorage
 * @param {Array} storageList All sessionStorage variables to validate
 * @param {String} redirectTo The Route to redirect to if a variable is not valid
 * @returns
 */
const checkPagePrerequisites = (storageList, redirectTo) => {
  if (storageList === null) return;
};
const checkPrerequisites = (storageVariable, schema, redirectTo) => {
  let data = sessionStorage.getItem(storageVariable) || null;
  if (data === null) alert('Should Redirect To: ', redirectTo);
};
// const checkPageForQuestionnaireData = () => {
//   let data =
//     JSON.parse(sessionStorage.getItem('questionnaire_selections')) || null;
//   if (data === null) alert('Should Redirect To  route1 = ' / ';');

//   let validated = validateData(data);
//   if (!validated) alert('Should Redirect To  route1 = ' / ';');
//   return;
// };
export const convertToLowerCase = (arr) => {
  //   console.log('this is arr', arr);
  if (arr == null) return null;
  let newArr = arr.map((val) => {
    if (typeof val === 'string') {
      return val.toLowerCase();
    }
  });
  return newArr;
};
