/**
 * Failed - The payment attempt was unsuccessful, possibly due to insufficient funds, incorrect card details, or other issues.
 * When a payment fails, the order is marked as "Not Completed" or "Declined" and the amount is not transferred to the
 * merchant's account. Failed transactions appear in Stripe's Failed payments report.
 *
 * Incomplete - The payment process was started but not completed. This could happen if the customer abandons the checkout
 * session or doesn't select Stripe as the payment method.
 */

import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { paymentURL } from '../api/urls.js';
import { mapAppointmentLength } from '../utils/tools/tools.js';
import { ReactComponent as CheckMark } from '../assets/icons/check-mark.svg';
import { ReactComponent as AlertIcon } from '../assets/icons/alert-circle-outline.svg';
import { useNavigate } from 'react-router-dom';

const ConfirmationRouter = ({ nextPath }) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(nextPath);
  };
  const [status, setStatus] = useState(null);
  const [customerData, setCustomerData] = useState('');
  const [responseReceived, setResponseReceived] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // First Check if the appointment can be made
    let getAppointmentAvailability = async () => {
      let stripeObject;
      try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');
        let stripeResponse = await fetch(
          `${paymentURL}/session-status?session_id=${sessionId}`
        );
        stripeObject = await stripeResponse.json();
        setStatus(stripeObject.status);
        setCustomerData(stripeObject.customer_details);
      } catch (error) {
        // Handle Stripe Error Here
        setError(true);
        setResponseReceived(true);
        return;
      } finally {
        setResponseReceived(true);
      }

      // Probably check if Stripe sent a good Response here
      // And return if it is bad or continue if response is good (paid)
    };

    // Check if we made a client successfully if not then return
    // else proceed

    getAppointmentAvailability();
  }, []);

  if (status === 'open') {
    return <Navigate to={`/`} />;
  }

  console.log('status:', status);
  console.log('responseReceived', responseReceived);
  if (status === 'complete') {
    if (!responseReceived) {
      return <p>Creating appointment...</p>;
    } else {
      return (
        <div className="secondary-bcg">
          {!error ? (
            <section
              id="success"
              className="gradient-bcg flex-column-direction height-100 flex-column-align-items-center"
            >
              <CheckMark className="flex-column-self-center bottom-margin "></CheckMark>
              <p className="text-align-center semi-bold bottom-margin width-75 flex-column-self-center">
                Your appointment was made successfully! An email confirmation
                was sent to {customerData.email}.
                <br className="bottom-margin"></br> If you have any questions,
                please email{' '}
                <a href="mailto:help@empoweredmovementtherapy.com">help@empoweredmovementtherapy</a>.
              </p>
              <button
                onClick={handleNavigation}
                className=" finish-button width-50  accent-bcg clear-border flex-column-self-center large-text white-color semi-bold"
              >
                {' '}
                Home
              </button>
            </section>
          ) : (
            <section
              id="error"
              className="error-gradient-bcg flex-column-direction height-100 flex-column-align-items-center"
            >
              <AlertIcon className="flex-column-self-center bottom-margin "></AlertIcon>
              <p className="text-align-center semi-bold bottom-margin width-75 flex-column-self-center">
                error creating appointment contact this number for further help
              </p>
            </section>
          )}
        </div>
      );
    }
  } else {
    return (
      <div>
        <div>Unable to process payment. Try again?</div>
        <button>Retry</button>
      </div>
    );
  }
};

export default ConfirmationRouter;
