import z from 'zod';
import validator from 'validator';
import { pstdayjs } from '../calendar/calendarTools.js';
import { getUserSelection } from '../persistence/dataPersistence.js';

export function isPostalCode(string) {
  return validator.isPostalCode(string, 'US');
}
export const convertToLowerCase = (arr) => {
  //   console.log('this is arr', arr);
  if (arr == null) return null;
  let newArr = arr.map((val) => {
    if (typeof val === 'string') {
      return val.toLowerCase();
    }
  });
  return newArr;
};

export const locationSchema = z.object({
  zip: z.string().refine(isPostalCode),
  addressLine1: z.string().max(50),
  city: z.string().max(50),
  country: z.string().max(50),
});
export const reservationBodySchema = z.object({
  appointment_id: z.string().uuid(),
});
export const verifySessionData = async (
  storageVariable,
  schema,
  nav,
  redirectLocation
) => {
  let data = await getUserSelection(storageVariable);
  if (data === null) nav(redirectLocation);
  let result = schema.safeParse(data);
  console.log('data', data);
  console.log(result.error);
  if (result.success) return;
  else nav(redirectLocation);
};

export const validQuestionnaireData = async (storageVariable, schema) => {
  let data = await getUserSelection(storageVariable);
  console.log('Questionnaire data', data);
  if (data === null) return false;
  let result = schema.safeParse(data);
  if (result.success) return true;
  else return false;
};
