import React, { useState } from 'react';
import NavBar from '../components/common/NavBar';
import CustomHero from '../components/common/CustomHero';
import NavigationButton from '../components/common/NavigationButton';
import { ListItemAO } from '../snippets/ListItem';

const Disclaimers = ({ onSubmit, option }) => {
    const [disclosureBox, setDisclosurBox] = useState(false);

    const handleCheckboxChange = (e) => {
        setDisclosurBox(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!disclosureBox) {
            // alert('You must accept the privacy policy before submitting.');
            return;
        }
        onSubmit();
    };

    return (
        <div className='page-layout__stacked'>
            <NavBar />
            <CustomHero
                firstLine="Important Health"
                secondLine="Considerations"
                subheading="To ensure your safety and comfort."
            />

            <form onSubmit={handleSubmit} id='disclaimer-form' className='page-width align-self__center section__fill-remainder align-content__vertical' style={{ width: '100%' }}>
                <section className='section borders__rounded secondary-bcg section section__fill-remainder'>
                    <div className=''>
                        {option && (
                            option.choices.map((choice, index) => {
                                return (
                                    <div key={choice.id} className={`list-item-wrapper text-primary`}>
                                        <ListItemAO
                                            title={choice.title}
                                            descriptionBullets={choice.bullet_points}
                                            isExpanded={true}
                                            showSmallButton={false}
                                            showIcon={false}
                                        />
                                    </div>
                                );
                            })
                        )}
                    </div>
                </section>

                <section className='disclaimer-section section borders__rounded secondary-bcg container-padding'>
                    <p className='text-primary text-align-left'>
                        If any of these conditions apply, email us at <a href='/contact-us'>info@empoweredmovementtherapy.com</a> with a brief description before booking.
                    </p>
                    <div id='disclaimer-checkbox' className='flex-row-direction flex-column-container-gap'>
                        <label className='text-primary align-text__left normal-text-small-bold'>
                            By proceeding I have disclosed any of the stated conditions
                        </label>
                        <input
                            type="checkbox"
                            name="privacyPolicyAccepted"
                            checked={disclosureBox}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                </section>

                <section className='section-nav'>
                    <NavigationButton description={`Understood. Let's Book!`} isDisabled={!disclosureBox} />
                </section>
            </form>
        </div>
    );
};

export default Disclaimers;
