import React, { useState, useEffect } from 'react';
import NavBar from "../components/common/NavBar";
import CustomHero from "../components/common/CustomHero";
import ListSection from "../sections/ListSection";
import { ListItemMT ,ListItemMD } from "../snippets/ListItem";
import Divider from "../snippets/decorative/Divider";
import NavigationButton from "../components/common/NavigationButton";
import { saveQuestionnaireSelection } from '../utils/persistence/dataPersistence';

const NestedSelector = ( { onSubmit, options } ) => {
    const [option1Selections, setOption1Selections] = useState([]);
    const [option2Selections, setOption2Selections] = useState([]);
    const [disabledSubmitButton, setIsButtonDisabled] = useState(true);

    const option1 = options[0];
    const option2 = options[1];

    useEffect(() => {
        const option1IsValid = !option1.settings?.required || option1Selections.length > 0;
        const option2IsValid = !option2.settings?.required || option2Selections.length > 0;
        setIsButtonDisabled(!(option1IsValid && option2IsValid));
    }, [option1Selections, option2Selections]);


    const handleSelection = (choiceId, selections, setSelections, isMultiSelect) => {
        if (isMultiSelect) {
            setSelections(prevSelections =>
                prevSelections.includes(choiceId)
                    ? prevSelections.filter(id => id !== choiceId)
                    : [...prevSelections, choiceId]
            );
        } else {
            setSelections(prevSelections =>
                prevSelections.includes(choiceId)
                    ? [] // Deselect if already selected in single select mode
                    : [choiceId] // Select if not selected
            );
        }
    };

    const onSelection1 = (choiceId) => {
        // Deselect both options
        if(option1Selections.length > 0) {
            option2Selections.pop();
        }
        
        handleSelection(choiceId, option1Selections, setOption1Selections, option1.settings?.multi_select);
    };

    const onSelection2 = (choiceId) => {
        handleSelection(choiceId, option2Selections, setOption2Selections, option2.settings?.multi_select);
    };

    const submitForm = (event) => {
        event.preventDefault();

        //TODO: Make it so that it can hadle multi-select
        saveQuestionnaireSelection(option1Selections, option1.id);
        saveQuestionnaireSelection(option2Selections, option2.id);

        onSubmit();
    };

    return (
        <div className='page-layout__stacked'>
            <NavBar showBack={false} />
            {/* TODO: Fill Hero data from optionParcel */}
            <CustomHero
                firstLine="What's your healing"
                secondLine="goal?"
                subheading="Rejuvenate and empower your body"
            />

            <form onSubmit={submitForm} id='form-nested-selections' className='page-width align-self__center section__fill-remainder align-content__vertical' style={{ margin: 0 }}>
                <ListSection>
                    {option1 && (
                        option1.choices.map((choice, index) => {
                            const isLastChoice1 = index === option1.choices.length - 1;
                            let listItemClassName = (option1Selections.length === 0 || option1Selections.includes(choice.id)) 
                                ? 'list-item__shown' 
                                : 'list-item__shrink';

                            return (
                                <div key={choice.id} className={`list-item-wrapper ${listItemClassName}`}>
                                    <ListItemMT
                                        id={choice.id}
                                        title={choice.title}
                                        description={choice.description}
                                        price={choice.price + option2.choices[0].price}
                                        onToggle={() => onSelection1(choice.id)}
                                        isSelected={option1Selections.includes(choice.id)}
                                        showStartingAtText={true}
                                        imgSrc={choice.imgUrl}
                                        >

                                            <ListSection>
                                                {option2 && option2.choices.map((choice2, index2) => {
                                                    const isLastChoice2 = index2 === option2.choices.length - 1;
                                                    return (
                                                        <div key={choice2.id}>
                                                            <ListItemMD 
                                                                title={choice2.title}
                                                                description={choice2.description}
                                                                price={choice.price + choice2.price}
                                                                onSelected={() => onSelection2(choice2.id)}
                                                                isSelected={option2Selections.includes(choice2.id)}
                                                                headerOverride={"h6"}
                                                                showStartingAtText={false}
                                                                imgSrc={choice2.imgUrl}
                                                            />
                                                            {!isLastChoice2 && <Divider />}
                                                        </div>
                                                    );
                                                })}
                                            </ListSection>

                                    </ListItemMT>
                                    
                                    {(!isLastChoice1 || option1Selections.includes(choice.id)) && <div className='divider-wrapper'><Divider /></div>}
                                </div>
                            );
                        })
                    )}
    
                </ListSection>
                <section className='section-nav'>
                    <NavigationButton description="Continue" isDisabled={disabledSubmitButton}/>
                </section>
            </form>
        </div>
    );
}

export default NestedSelector;