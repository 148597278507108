import {
  deleteUserSelection,
  getUserSelection,
} from '../../utils/persistence/dataPersistence.js';
import React, { useEffect, useMemo, useState } from 'react';
import { pstdayjs } from '../../utils/calendar/calendarTools.js';
import Countdown, { zeroPad } from 'react-countdown';
import { Navigate } from 'react-router-dom';
const CountdownInitializer = ({ expirationTime }) => {
  let [endTime, setEndTime] = useState(null);
  const [displayCountdown, setDisplayCountdown] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  if (expirationTime !== endTime && expirationTime) {
    setEndTime(expirationTime);
  }
  const onComplete = () => {
    deleteUserSelection('reservationInfo');
    alert('Time Limit Reached. You Will now be redirected');
    if (window.location.pathname.includes('/set-your-time'))
      window.location.reload();
    else setShouldRedirect(true);
  };
  useEffect(() => {
    const timerSetup = async () => {
      try {
        const data = await getUserSelection('reservationInfo');

        if (data === null) return;
        let expirationTime = pstdayjs(data.expires);

        if (pstdayjs().isAfter(expirationTime)) {
          deleteUserSelection('reservationInfo');
          return;
        }

        setEndTime(expirationTime);
        setDisplayCountdown(true);
      } catch (error) {
        console.log('Error:', error);
      }
    };
    timerSetup();
  }, []);
  return (
    <>
      {shouldRedirect && <Navigate to="/set-your-time" replace={true} />}
      {displayCountdown && endTime && (
        <Countdown
          onComplete={() => {
            onComplete();
          }}
          date={endTime.valueOf()}
          renderer={(props) => (
            <div className=" container-padding flex-column-direction flex-align-items-end  ">
              <span className="secondary-bcg btn-xs text-align-center ">
                <span className="text-primary xs-text-bold">
                  Time Remaining
                </span>
                <br></br>
                {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
              </span>
              {/* <p className="normal-text-small-bold bottom-margin-xs  ">
                Time Remaining
              </p>
              <span className="secondary-bcg btn-small normal-text-small-bold">
                {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
              </span> */}
            </div>
          )}
        ></Countdown>
      )}
    </>
  );
};
export default CountdownInitializer;
