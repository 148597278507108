import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { saveSensitiveUserData } from '../utils/persistence/dataPersistence';
import AddressForm from '../components/inputFields/AddressField';

import CustomHero from '../components/common/CustomHero.jsx';
import NavBar from '../components/common/NavBar.jsx';

const LocationDetails = ({ nextPath, prevPath, verify }) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(nextPath);
  };

  const handlePrevPath = () => {
    navigate(prevPath);
  };
  useEffect(() => {
    verify(navigate);
  });
  // TODO handle user location input
  // const handleLocationInput = async (dateObj) => {
  //   saveSensitiveUserData(dateObj, dataNames.DATE);
  //   handleNavigation();
  // };
  return (
    <div className="page-layout__stacked">
      <NavBar callback={handlePrevPath}></NavBar>
      <CustomHero
        firstLine="We'll Come"
        secondLine="To You"
        subheading="Enter your address and let us bring the therapy to you."
      ></CustomHero>

      <AddressForm
        saveSensitiveData={saveSensitiveUserData}
        handleNavigation={handleNavigation}
      />
    </div>
  );
};
export default LocationDetails;
