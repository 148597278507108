import TimeSection from './TimeSection';
import React, { useState } from 'react';
/**
 *
 * @param {*} param0
 * @returns
 */
function TimeContainer({
  activeIndex,
  handleClick,
  canWorkToday,
  timesToDisplay,
}) {
  const [selectedTime, setSelectedTime] = useState('');

  if (!canWorkToday) {
    return null;
  }

  const sectionNames = ['Morning', 'Afternoon', 'Evening'];

  let remainingTimeSlots = splitAppTimesIntoSections(timesToDisplay);

  let areAppsAvailable = remainingTimeSlots.flat().length > 0;
  let numberOfSections = remainingTimeSlots.reduce((accumulator, elem) => {
    if (elem.length > 0) return ++accumulator;
    return accumulator;
  }, 0);

  let listStyle =
    numberOfSections === 1
      ? 'flex-justify-start'
      : 'flex-justify-content-space-evenly';
  // console.log('listStyle', listStyle);

  return (
    <section
      className={`${listStyle} time-section container-padding list-gap `}
    >
      <div className={'time-section--content'}>

      {areAppsAvailable ? (
        remainingTimeSlots.map((arr, index) => {
          return (
            arr.length > 0 && (
                <React.Fragment key={sectionNames[index]}>
                  <TimeSection
                    callback={handleClick}
                    activeIndex={activeIndex}
                    times={arr}
                    title={sectionNames[index]}
                    setSelectedTime={setSelectedTime}
                    selectedTime={selectedTime}
                  ></TimeSection>
                  {/* TODO: Causing a key error since hr is a list item */}
                  {index !== remainingTimeSlots.length - 1 && <hr></hr>}
                </React.Fragment>
            )
          );
        })
      ) : (
        <h2>No Available Appointments</h2>
      )}
      </div>
    </section>
  );
}

function splitAppTimesIntoSections(originalTimes) {
  let morningArr = [];
  let afternoonArr = [];
  let eveningArr = [];

  originalTimes.forEach((time) => {
    let hour = parseInt(time.split(':')[0]);
    if (time.includes('am')) {
      morningArr.push(time);
    } else if (hour % 12 < 5) {
      afternoonArr.push(time);
    } else {
      eveningArr.push(time);
    }
  });
  return [morningArr, afternoonArr, eveningArr];
}

export default TimeContainer;
