import { pstdayjs } from '../../../utils/calendar/calendarTools.js';

/**
 * Creates a CalendarManager object with the starting
 * date (originalDate) set to today.
 */
export default class CalendarManager {
  constructor(date) {
    // Testing purposes

    this.originalDate = new Date();
    this.viewingDate = date;

    this.dayMap = new Map();

    this.day = -1;
    this.dayMap.set('January', 31);
    this.dayMap.set('March', 31);
    this.dayMap.set('April', 30);
    this.dayMap.set('May', 31);
    this.dayMap.set('June', 30);
    this.dayMap.set('July', 31);
    this.dayMap.set('August', 31);
    this.dayMap.set('September', 30);
    this.dayMap.set('October', 31);
    this.dayMap.set('November', 30);
    this.dayMap.set('December', 31);
  }

  nextViewingMonth = () => {
    let newDate = pstdayjs(this.viewingDate).add(1, 'M');
    this.viewingDate = newDate.toDate();
  };
  previousViewingMonth = () => {
    this.viewingDate = this.originalDate;
  };

  /**
   * Used to calculate days in February
   *
   * @returns {Int} Returns 28 or 29 based if viewingDate is a leap year on February
   */
  calcFebDays = () => {
    if (this.viewingDate.getFullYear() % 4 === 0) {
      return 29;
    } else {
      return 28;
    }
  };

  /**
   * Returns a list of integers from 0 - 31 based on viewingDate representing days of the month.
   * example. viewingDate == January so it will return [0,1,...,31]
   *
   * @returns {Array} An array of Integers
   */
  getCalendarDays = () => {
    let month = this.getMonthName();
    let numberOfDays = 0;

    if (month === 'February') {
      numberOfDays = this.calcFebDays();
    } else {
      numberOfDays = this.dayMap.get(this.getMonthName());
    }

    return Array.from({ length: numberOfDays }, (_, i) => i + 1);
  };

  /**
   * Returns the name of the viewingDate month.
   *
   * @returns {String} String name of a month of viewingDate ex. "June"
   */
  getMonthName = () => {
    return this.viewingDate.toLocaleDateString('default', { month: 'long' });
  };

  /**
   * returns the previous months and next month days to display as filler days
   *
   * as an object with two list.
   *
   * @returns {Object}
   */
  calcFillerDays = () => {
    let newDate = new Date(
      this.viewingDate.getFullYear(),
      this.viewingDate.getMonth(),
      1
    );
    let nextMonth = new Date(
      this.viewingDate.getFullYear(),
      this.viewingDate.getMonth() + 1,
      1
    );
    let prevMonthDays = [];
    let nextMonthDays = [];
    let numberOfPrevDays = newDate.getDay();
    for (let i = 0; i < numberOfPrevDays; i++) {
      newDate.setDate(newDate.getDate() - 1);
      prevMonthDays.unshift(newDate.getDate());
    }

    for (let i = 0; i < 7 - nextMonth.getDay(); i++) {
      nextMonthDays.push(i + 1);
    }

    // Used to delete row full of next month days only and which don't contain
    // Current Month days
    if (nextMonthDays.length === 7) {
      nextMonthDays = [];
    }
    return {
      prevMonthDays,
      nextMonthDays,
    };
  };
}
