import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { PAYMENT_VENDOR_KEY } from '../../environmentSetup.js';
import { paymentURL } from '../../api/urls.js';
import CountdownInitializer from '../common/CountdownInitializer.jsx';
import { checkReservation } from '../../utils/tools/timerSetup.js';

// console.log('--------------------');
// console.log(PAYMENT_VENDOR_KEY);
// console.log('--------------------');
const stripePromise = loadStripe(`${PAYMENT_VENDOR_KEY}`);
// const stripePromise = loadStripe(stripeKey);

const StripeIntegration = (userSelections) => {
  const [clientSecret, setClientSecret] = useState('');
  const [expirationTime, setExpirationTime] = useState(null);

  useEffect(() => {
    // const priceData = { unit_amount: 17500 };
    const fetchData = async () => {
      try {
        const res = await fetch(`${paymentURL}/create-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userSelections),
        });
        const data = await res.json();
        console.log('data here', data);
        setClientSecret(data.clientSecret);
      } catch (error) {
        console.error('Failed to fetch client secret:', error);
      }
    };

    fetchData();
    checkReservation(setExpirationTime);
  }, [paymentURL]);

  return (
    <div>
      <CountdownInitializer expirationTime={expirationTime} />
      <div id="deposit-container">
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  );
};

export default StripeIntegration;
