import dayjs from 'dayjs';
import {
  getMonthName,
  pstdayjs,
  convertAmPmToHoursMins,
} from '../../../utils/calendar/calendarTools.js';

import { parseDateTimeToHour } from '../../../utils/parsers/dateTimeParse.js';
export default class Schedule {
  constructor(availabilityObject = {}, reqDaysOff = [], lengthOfTimeSlot = 30) {
    this.scheduledDays = []; // [0,1,2,3,4,5,6]
    this.reqDaysOff = reqDaysOff;

    this.workingHours = {};
    this.maxAppsPerDay = 1;
    this.mapOfDaysToAppointments = new Map();
    this.startDate = '';
    this.endDate = '';
    this.#convertAvailabilityObjectToHours(availabilityObject);
    this.#calculateWorkingDaysOffWeek();

    this.lengthOfTimeSlot = lengthOfTimeSlot;
  }

  getOpenTimesForDay(date, appointmentTimeLength) {
    let copy = pstdayjs(date);

    let appointmentsForSelectedDay = this.#getAppointmentsForADay(copy);
    let hoursAvail = this.#getHourAvailForDay(copy.day());

    let times = this.#genTimes(
      hoursAvail.start,
      hoursAvail.end,
      this.lengthOfTimeSlot
    );

    // idk how we should handle same day appointments
    let temp;
    if (pstdayjs().isSame(copy, 'date')) {
      let today = pstdayjs();
      temp = times.filter((time) => {
        let { hours, minutes } = convertAmPmToHoursMins(time);
        let changingDate = pstdayjs().set('hour', hours).set('minute', minutes);
        if (!changingDate.isBefore(today)) return time;
      });
      times = temp;
    }
    let availableTimesSlots = this.#filterUnavailableTimes(
      times,
      appointmentsForSelectedDay,
      this.lengthOfTimeSlot,
      30,
      appointmentTimeLength
    );
    return availableTimesSlots;
  }
  /**
   * Gets the soonest available day of work for a therapist as an integer
   *
   * @param {dayjs} date date object used to get month value
   * @returns {Integer} returns -1 if no available days else returns the next available day
   */
  getSoonestAvailableWorkday(date) {
    let copyDate = pstdayjs(date);

    copyDate = copyDate.set('date', 1);
    let day = -1;
    let limit = copyDate.daysInMonth();

    for (let i = 0; i < limit; i++) {
      let movingDate = pstdayjs(copyDate).set('date', copyDate.get('date') + i);
      if (this.checkIfCanWorkThisDay(movingDate)) {
        return movingDate.get('date');
      }
    }

    return day;
  }
  /**
   * Checks if a therapist can work this date based on: their weekly schedule, requested days off,
   * if the day has already passed and if they already have maximum desired appointments for the date.
   *
   * @param {dayjs} date the date object that will be used to check against the therapist availability
   * @returns {Boolean} True if they can work else false if they cant
   */

  checkIfCanWorkThisDay(date) {
    let today = pstdayjs().startOf('date');
    let copyDate = pstdayjs(date).startOf('date');
    let startDate = pstdayjs(this.startDate, true).startOf('D');
    let endDate = pstdayjs(this.endDate, true).endOf('D');
    let dayIsInThePast = copyDate.isBefore(today, 'date');
    let dayNotInScheduleRange = !(
      copyDate.isAfter(startDate) && copyDate.isBefore(endDate)
    );
    let isFullyBookedForDay =
      this.#getAppointmentsForADay(copyDate).length >= this.maxAppsPerDay;

    let dayOff = !this.scheduledDays.includes(copyDate.get('day'));

    let isRequestedDayOff = this.#checkIfRequestedDayOff(copyDate);

    return !(
      dayIsInThePast ||
      isFullyBookedForDay ||
      dayOff ||
      isRequestedDayOff ||
      dayNotInScheduleRange
    );
  }
  /**
   * Checks if a date is in the requested days off list of the therapist
   *
   * @param {dayjs} date date that will be checked against the therapist requested days off
   * @returns {Boolean}  True | False
   */
  #checkIfRequestedDayOff(date) {
    let comparison = false;

    let observingDate = pstdayjs(date);
    this.reqDaysOff.forEach((obj) => {
      let comparingDate = pstdayjs(obj.reqDayOff);
      if (observingDate.isSame(comparingDate, 'd')) {
        comparison = true;
      }
    });

    return comparison;
  }
  /**
   *  Returns the appointments array for a given day
   *
   * @param {dayjs} date the date object want to reference to get appointments for that date
   * @returns {Array} returns an array of appointment objects [{appointmentTime,appointmentLength}]
   */
  #getAppointmentsForADay(date) {
    let key = `${getMonthName(date)} ${date.get('date')}`;
    let arr = [];
    if (this.mapOfDaysToAppointments.has(key)) {
      arr = this.mapOfDaysToAppointments.get(key);
    }
    return arr;
  }
  /**
   * Returns a map where the key is the monthName followed by the day number
   * the value is an array of appointment objects
   *
   * @param appointmentArray an array of appointment objects for a single date
   * @returns {Map} a map, Ex.map.get("February 21") == [{appointmentTime,appointmentLength}]
   */
  mapAppointments(appointmentArray) {
    let map = new Map();
    // TODO this is where we are converting UTC time to local time
    // careful
    appointmentArray.forEach((appointment) => {
      let appointmentDate = pstdayjs(appointment.appointmentTime);
      let monthName = getMonthName(appointmentDate);
      let key = `${monthName} ${appointmentDate.date()}`;
      if (map.has(key)) {
        map.get(key).push(appointment);
      } else {
        map.set(key, [appointment]);
      }
    });

    this.mapOfDaysToAppointments = map;
  }

  /**
   * returns the start and end time of a shift for a specific day of the week.
   *
   * @param {Integer} dayOfWeek integer value 0-6 representing sun-sat
   * @returns {Object} an object containing start time and end time of shift for a day
   */
  #getHourAvailForDay(dayOfWeek) {
    let map = new Map();
    map.set(0, 'sunday');
    map.set(1, 'monday');
    map.set(2, 'tuesday');
    map.set(3, 'wednesday');
    map.set(4, 'thursday');
    map.set(5, 'friday');
    map.set(6, 'saturday');

    let viewingDay = map.get(dayOfWeek);
    return this.workingHours[viewingDay] || {};
  }
  /**
   * Generates an array of time strings from startTime to endTime.
   *
   * @param {Integer} startTime Time when you want appointments to start occurring, this value is included in calculation
   * @param {Integer} endTime Time when you want appointments to end, value is not included
   * @param {Integer} intervalLength 15 | 30 value used to make 15 or 30 minute appointments.
   * @returns {Array} a string array containing appointment times. Ex [1:30 pm, 2:00 pm]
   */
  #genTimes(startTime, endTime, intervalLength) {
    if (startTime == null || endTime == null) {
      return [];
    }

    if (startTime >= endTime) return [];
    const isOutOfBounds = (x) => x > 24 || x < 0;
    if (isOutOfBounds(startTime) || isOutOfBounds(endTime)) return [];
    // TODO: Test commented code below should replace other logic to calculate times.

    // let beginning = pstdayjs().set('hour', startTime).set('m', 0);
    // let ending = pstdayjs().set('hour', endTime).set('m', 0);
    // let tempArray = [];

    // while (beginning.isBefore(ending)) {
    //   tempArray.push(beginning.format('h:mm a'));
    //   beginning = beginning.add(intervalLength, 'm');
    // }
    let divisor = 2;

    if (intervalLength === 15) {
      divisor = 4;
    }
    let hoursArr = Array.from(
      { length: endTime - startTime },
      (_, i) => i + startTime
    );

    let allTimesArr = [];
    hoursArr.forEach((hour) => {
      for (let i = 0; i < divisor; i++) {
        let time;
        if (hour % 12 === 0) {
          time = 12;
        } else {
          time = hour % 12;
        }
        time += ':';
        let result = intervalLength * i;
        if (result === 0) {
          time += '00 ';
        } else {
          time += result + ' ';
        }

        if (hour >= 12) {
          time += 'pm';
        } else {
          time += 'am';
        }
        allTimesArr.push(time);
      }
    });

    return allTimesArr;
  }
  /**
   * Removes available time slots that have already been booked previously.
   *
   * @param {Array} originalTimes An Array that contains all the time slots possible for a therapist
   * @param {Array} unavailableTimes An array of appointment objects [{appointmentTime,appointmentLength}]
   * @param {Int} timeSlotLength Integer representation of time between time slots 15 | 30
   * @param {Integer} [bufferLength = 30] the amount of time that is needed to clean up after an appointment
   * @returns {Array} An array that contains all the available times for a therapist for a day
   */
  #filterUnavailableTimes(
    originalTimes,
    unavailableTimes,
    timeSlotLength,
    bufferLength = 30,
    selectedLength
  ) {
    if (unavailableTimes == null) {
      return originalTimes;
    }
    if (unavailableTimes.length === 0) {
      return originalTimes;
    }
    let set = new Set();

    // TODO: if we are looking at todays date then we should remove timeslots
    // that are in the past. Example if its 12:30 pm, the user should not
    // see 8:00 am , 9:00 am and so on. But in order to do this this would
    // only work if the user was in the Pacific time zone.
    // Example. Its 10:30 am in california but lets say the user was in texas
    // which would be 12:30 pm. So the code will remove all times before 12:30 pm

    // Getting times to remove based on made appointments
    // console.log('Printing appointments for a day');
    unavailableTimes.forEach((time) => {
      let parsedTime = pstdayjs(time.appointmentTime).second(0);
      // console.log(time.appointmentTime);
      // console.log(parsedTime.format());
      let timesToRemove = this.#calculateUnavailableTimesFromAppointment(
        parsedTime,
        time.appointmentLength,
        timeSlotLength,
        bufferLength,
        selectedLength
      );

      timesToRemove.forEach((x) => set.add(x));
    });

    let newTimes = originalTimes.filter((time) => {
      return !set.has(time);
    });

    return newTimes;
  }
  /**
   *
   * Takes in an appointment that is already made (from DB) and the length of the current appointment trying to be made
   * right now and returns times that could over lap.
   *
   * @param {dayjs} appointmentDate the dayjs object that will be used to obtain hour and minute data
   * @param {Integer} appointmentLength 60 | 90 | 120 integer to represent time length in minutes of an appointment previously made
   * @param {Integer} timeIntervalLength 15 | 30 the time between time slots in minutes
   * @param {Integer} bufferLength the amount of prep time needed after an appointment in minutes
   * @param {Integer} currentAppointmentLength The length of appointment time for appointment the client is trying to make right now
   * @returns {Array} an array of unavailable times Ex. [12:30 pm, 12:45 pm, ... , 2:00 pm]
   */
  #calculateUnavailableTimesFromAppointment(
    appointmentDate,
    appointmentLength,
    timeIntervalLength,
    bufferLength,
    currentAppointmentLength
  ) {
    let appointmentDuration = appointmentLength + bufferLength;
    let endTime = appointmentDate.add(appointmentDuration, 'minute');

    let startTime = appointmentDate.subtract(
      currentAppointmentLength + bufferLength - timeIntervalLength,
      'minute'
    );
    if (!startTime.isSame(appointmentDate, 'date')) {
      startTime = startTime.add(1, 'day').startOf('day');
    }
    if (!endTime.isSame(appointmentDate, 'date')) {
      endTime = endTime.subtract(1, 'day').endOf('day');
    }
    let times = [];

    while (startTime.isBefore(endTime, 'm')) {
      times.push(startTime.format('h:mm a'));
      startTime = startTime.add(timeIntervalLength, 'm');
    }

    return times;
  }
  /**
   * Converts the availability object's DATETIME variables to and object that contains
   * integers that represent start and end times for each day. Stored into this.workingHours
   *
   * @param {Object} availabilityObject the availability object obtained from the database
   *
   */
  #convertAvailabilityObjectToHours(availabilityObject) {
    let blockDates = false;
    if (availabilityObject == null) {
      blockDates = true;
    }
    let endOfNextMonth = pstdayjs().add(1, 'M').endOf('M');
    let dayNames = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    let {
      maxAppsPerDay,
      timeRange: [startDate, endDate],
      ...availabilitySchedule
    } = availabilityObject;
    this.startDate = startDate;
    this.endDate =
      endDate === null ? endOfNextMonth.format('YYYY-MM-DD') : endDate;
    let obj = {};

    // Checking which days are not null and which are
    dayNames.forEach((dayName, i) => {
      let [start, end] = availabilitySchedule[dayName] || [];
      let temp = {};
      if (start == null || end == null) {
        temp = null;
      } else {
        temp['start'] = parseDateTimeToHour(start);
        temp['end'] = parseDateTimeToHour(end);
      }
      obj[dayName] = temp;
    });
    this.workingHours = obj;
    this.maxAppsPerDay = maxAppsPerDay;
  }
  /**
   * Looks at the instance variable this.workingHours  ({sundayStart: 7, mondayStart: null})
   * and adds to the working days in this.scheduledDays based on if this.workingHours
   * property value is not null. Example sundayStart == 7 so we push 0 to represent sunday is
   * workable onto this.scheduledDays. Then don't do anything with mondayStart because it is
   * null.
   * @returns  {Array} An array containing numbers 0-6 representing days that are a scheduled work day
   */
  #calculateWorkingDaysOffWeek() {
    let keyNames = Object.keys(this.workingHours);
    let arr = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];

    let temp = [];
    keyNames.forEach((keyName) => {
      if (this.workingHours[keyName] != null) {
        let dayName = keyName.split(/(?=[A-Z])/)[0];
        temp.push(arr.indexOf(dayName));
      }
    });
    this.scheduledDays = [...new Set(temp)];
  }
}
