import dayjs from 'dayjs';
import { THERAPIST_ID } from '../../environmentSetup.js';
import {
  getAllUserSelections,
  saveSensitiveUserData,
} from '../../utils/persistence/dataPersistence.js';
import { mapAppointmentLength } from '../../utils/tools/tools.js';
import { baseURL } from '../urls.js';

/**
 * Searches for matching addresses based on the client input value in location search field.
 *
 * @param {String} address The string to send to google api get address search result for client address
 * @returns {Promise} returns a promise containing a list of matching addresses from google search api
 */
export const searchLocationAPI = async (address) => {
  let response = await fetch(`${baseURL}searchLocation`, {
    method: 'POST',
    body: JSON.stringify({ textQuery: address }),
    headers: {
      'Content-type': 'application/json',
    },
    mode: 'cors',
  });
  let data = await response.json();

  return data;
};

export const autoCompleteAPIGet = async (input) => {
  let response = await fetch(`${baseURL}locationSearch?input=${input}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    mode: 'cors',
  });
  return response;
};
/**
 * Returns a promise which should resolve to a list of autocompleted addresses given
 * a substring.
 *
 * @param {Object} postBody objects containing a string that will be used for googles autocomplete api
 * @returns {Promise}
 */
export const autoCompleteAPI = async (postBody) => {
  let response = await fetch(`${baseURL}locationSearch`, {
    body: JSON.stringify(postBody),
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    mode: 'cors',
  });
  let data = await response.json();

  return data;
};

/**
 * Returns a promise that resolves to a google formatted address based on the google places id given.
 *
 * @param {String} id The string id given by google places api
 * @returns
 */
export const searchLocationId = async (id) => {
  let response = await fetch(`${baseURL}searchLocationId/${id}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
    },
    mode: 'cors',
  });
  let data = await response.json();
  return data;
};

/**
 * Sends a create appointment request to the backend. By using values obtained
 * from session storage and the parameters clientId and therapistId.
 *
 * @param {INTEGER} clientId the client that will be tied to this appointment
 * @param {INTEGER} therapistId the therapist that will be assigned to this appointment
 */
export const createAppointment = async (clientId, therapistId) => {
  try {
    const selections = await getAllUserSelections();

    const service = {
      type: selections.questionnaire_selections.massage_type,
      duration: selections.questionnaire_selections.massage_duration,
      add_ons: selections.questionnaire_selections.add_ons,
      flags: selections.questionnaire_selections.flags,
    };

    const appointmentLength = await mapAppointmentLength(
      selections.questionnaire_selections
    );
    const appointmentLocation = selections.userAddress;
    const appointment = {
      clientId,
      therapistId,
      appointmentTime: selections.date,
      appointmentLength,
      depositCollected: false, // TODO
      appointmentStatus: 'PENDING', // TODO
      zipcode: appointmentLocation.zip,
      street: appointmentLocation.addressLine1,
      city: appointmentLocation.city,
      // appointmentLocation: ,
      service,
    };

    // console.log(appointment);

    await fetch(`${baseURL}appointments`, {
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(appointment),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    console.error('Error creating appointment:', error);
  }
};
/**
 * Sends a post request to the server to try to make a reservation
 * for the given appointment Time and Length with default therapist id.
 *
 * @param {dayjs} selectedAppointmentTime
 * @param {Integer} selectedAppointmentLength
 * @returns {Boolean} False if reservation could not be made | True if reservation was made
 */
export const createReservation = async (
  selectedAppointmentTime,
  selectedAppointmentLength
) => {
  let response = await fetch(`${baseURL}appointments/reservation`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({
      therapistID: THERAPIST_ID,
      appointmentTime: selectedAppointmentTime.toISOString(),
      appointmentLength: selectedAppointmentLength,
    }),
  });
  let data = await response.json();
  if (data.error || data.length === 0) return false;
  saveSensitiveUserData({ ...data[0] }, 'reservationInfo');
  return true;
};
export const updateReservation = async (
  appointmentID,
  selectedAppointmentTime,
  selectedAppointmentLength
) => {
  let response = await fetch(`${baseURL}appointments/reservation`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({
      therapistID: THERAPIST_ID,
      appointmentTime: selectedAppointmentTime.toISOString(),
      appointmentLength: selectedAppointmentLength,
      appointmentID,
    }),
  });
  let data = await response.json();
  if (data.error || data.length === 0) return false;
  saveSensitiveUserData({ ...data[0] }, 'reservationInfo');
  return true;
};
export const isReservationValidAndRefresh = async (uuid) => {
  let response = await fetch(`${baseURL}appointments/reservation?id=${uuid}`);
  let data = await response.json();

  return data;
};
export const isAppointmentTimeOpen = async (
  appointmentTime,
  appointmentLength,
  therapistId
) => {
  let encodedURI = encodeURI(
    `${baseURL}appointments/check?appointment-time=${appointmentTime}&appointment-length=${appointmentLength}&therapist-id=${therapistId}`
  );
  let response = await fetch(encodedURI, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
  return await response.json();
};
export const createNewClientAPI = async (
  name,
  email,
  phone,
  zipcode,
  street,
  city
) => {
  let newClient = { name, email, phone, zipcode, street, city };

  let res = await fetch(`${baseURL}clients`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(newClient),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
  return await res.json();
};
