import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/icons/Back.svg';

/**
 * Makes a Basic NavBar
 * @param {Function} callback function used to go previous page
 * @param {-} hasPrevious 
 * @returns
 */
const NavBar = ({ callback, showBack=true }) => {
  const navigate = useNavigate();

  const goBack = () => {
    // Check if there is a previous entry in the history stack.
    if (window.history.length > 1) {
      // Navigate back using React Router's navigate function.
      navigate(-1);
    } else {
      // Fallback: navigate to the home page (or another default route)
      navigate('/');
    }
  };

  return (
    <nav className="container-padding flex-row-direction">
      {showBack && (
        <BackArrow
          onClick={() => {
            callback ? callback() : goBack();
          }}
        />
      )}
      <h2 className="secondary-clr">Empowered Movement Therapy</h2>
    </nav>
  );
};

export default NavBar;
