import { useState, useEffect } from 'react';
import { questionaireURL } from '../api/urls.js';

/**
 * A custom hook for loading a configuration file.
 *
 * @returns An object containing the configuration data, loading state, and any error occurred.
 */
const useOptions = () => {
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('this is where iam ', questionaireURL);
    fetch(`${questionaireURL}/questions`, {
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        return response.json();
      })
      .then((data) => {
        // TODO: Validate proper config format
        setConfig(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setError(error);
        setLoading(false);
      });
  }, []);

  return { config, loading, error };
};

export default useOptions;
