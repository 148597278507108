import { useEffect, useState } from 'react';
// import './calendar.css';
import TimeContainer from './timeSelection/TimesContainer';
import CalendarRenderer from './calendar/CalendarRenderer';
import CalendarManager from './calendar/CalendarManager';
import {
  convertAmPmToHoursMins,
  pstdayjs,
} from '../../utils/calendar/calendarTools';

import { mapAppointmentLength } from '../../utils/tools/tools.js';
import { getUserSelection } from '../../utils/persistence/dataPersistence.js';
import NavigationButton from '../common/NavigationButton.jsx';
import CountdownInitializer from '../common/CountdownInitializer.jsx';
import {
  createReservation,
  updateReservation,
} from '../../api/fetchRequests/appointmentRequests.js';
import { checkReservation } from '../../utils/tools/timerSetup.js';
export const PST = 'America/Los_Angeles';
// EXPLANATION
// I use the selectedDay state to pick which day of the month we are looking at
// this will be used to index the appointments array to see which times are already taken
// which will feed into unavailableTimes of TimeContainer

function CalendarForm({ therapist, handleRoute, setRefresh, refresh }) {
  const [monthTracker, setMonthTracker] = useState(0);
  const [calMan, setCalMan] = useState(
    new CalendarManager(pstdayjs().toDate())
  );
  const [activeIndex, setActiveIndex] = useState('0');

  const [currentAppointmentLength, setAppointmentLength] = useState(0);
  const [selectedDay, setSelectedDay] = useState(-1);
  const [expirationTime, setExpirationTime] = useState(null);
  let selectedDate = pstdayjs(calMan.viewingDate).set('date', selectedDay);
  useEffect(() => {
    setSelectedDay(
      therapist.getSoonestAvailableWorkday(pstdayjs(calMan.viewingDate))
    );

    const fetchData = async () => {
      try {
        const data = await getUserSelection('questionnaire_selections');
        const duration = await mapAppointmentLength(data);
        setAppointmentLength(duration);
      } catch (err) {
        console.log('Error:', err);
      }
    };
    fetchData();
    checkReservation(setExpirationTime);
  }, []);

  function callback(offset) {
    setActiveIndex('0');
    if (offset === -1) {
      calMan.previousViewingMonth();
    } else {
      calMan.nextViewingMonth();
    }
    setCalMan(new CalendarManager(calMan.viewingDate));
    setMonthTracker(monthTracker + offset);
    setSelectedDay(
      therapist.getSoonestAvailableWorkday(pstdayjs(calMan.viewingDate))
    );
  }

  function onDayClick(newDay) {
    setSelectedDay(newDay);
    setCalMan(new CalendarManager(calMan.viewingDate));
    setActiveIndex('0');
  }

  async function onSubmit(e) {
    e.preventDefault();

    let { hours, minutes } = convertAmPmToHoursMins(activeIndex);
    let newDate = pstdayjs()
      .set('year', calMan.viewingDate.getFullYear())
      .set('month', calMan.viewingDate.getMonth())
      .set('date', selectedDay)
      .set('hour', hours)
      .set('minute', minutes)
      .set('second', 0)
      .set('millisecond', 0);
    let madeReservation;

    // TODO: Prompt user to pick a different time? and refresh page?
    const data = await getUserSelection('reservationInfo');
    if (data) {
      madeReservation = await updateReservation(
        data.appointment_id,
        newDate,
        currentAppointmentLength
      );
    } else {
      madeReservation = await createReservation(
        newDate,
        currentAppointmentLength
      );
    }
    if (!madeReservation) {
      alert('Time has recently been taken. Please select a new time.');
      setRefresh((refresh + 1) % 2);
    } else {
      handleRoute(newDate);
    }
  }

  function handleClick(index) {
    console.log(index);
    setActiveIndex(index);
  }

  let canWorkToday = therapist.checkIfCanWorkThisDay(selectedDate);
  let timesToDisplay = therapist.getOpenTimesForDay(
    selectedDate,
    currentAppointmentLength
  );

  return (
    <form
      onSubmit={onSubmit}
      className="calendar-form page-width"
    >
      <main
        id="caledar-main-content"
        className="flex-column-container-gap calendar-form--content"
      >
        <CountdownInitializer
          expirationTime={expirationTime}
        ></CountdownInitializer>

        <div class="calendar-form--item">
          <CalendarRenderer
            onDayClick={onDayClick}
            monthTracker={monthTracker}
            calMan={calMan}
            callback={callback}
            therapist={therapist}
            selectedDay={selectedDay}
          ></CalendarRenderer>
        </div>
        <div class="calendar-form--item">
          <TimeContainer
            activeIndex={activeIndex}
            handleClick={handleClick}
            canWorkToday={canWorkToday}
            timesToDisplay={timesToDisplay}
          ></TimeContainer>
        </div>
      </main>
      <section className="section-nav text-align-center">
        {activeIndex !== '0' && selectedDay !== -1 ? (
          <NavigationButton
            description="Confirm Time"
            isDisabled={false}
          ></NavigationButton>
        ) : (
          // <input disabled={true} type="submit"></input>
          <NavigationButton
            description="Confirm Time"
            isDisabled={true}
          ></NavigationButton>
        )}
      </section>
    </form>
  );
}

export default CalendarForm;
