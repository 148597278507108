import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  dataNames,
  getUserSelection,
  saveSensitiveUserData,
} from '../utils/persistence/dataPersistence';
import CalendarForm from '../components/calendarComponents/CalendarForm';

import { snakeToCamel } from '../utils/parsers/objectParse.js';
import { parseTSRANGE } from '../utils/calendar/calendarTools.js';
import { baseURL } from '../api/urls.js';

import { THERAPIST_ID } from '../environmentSetup.js';
import CustomHero from '../components/common/CustomHero.jsx';
import NavBar from '../components/common/NavBar.jsx';
import ScheduleManager from '../components/calendarComponents/objects/ScheduleManager.js';
import CalendarStub from '../components/calendarComponents/stubs/calendarStub.jsx';
import {
  locationSchema,
  verifySessionData,
} from '../utils/validators/schemas.js';

const AppointmentDetails = ({ nextPath, prevPath, verify }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(nextPath);
  };
  const handlePrevPath = () => {
    navigate(prevPath);
  };
  const handleAppointmentSelection = async (dateObj) => {
    saveSensitiveUserData(dateObj, dataNames.DATE);
    handleNavigation();
  };

  const [therapist, setTherapist] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    verify(navigate);

    verifySessionData(
      'userAddress',
      locationSchema,
      navigate,
      '/set-your-place'
    );
    // TODO remove or highlight reserved date? from appointment list based on local sessions

    const setupSchedule = async () => {
      let queryAddition = '';
      let data = await getUserSelection('reservationInfo');
      // console.log('data', data);
      if (data) {
        queryAddition = `?id=${data.appointment_id}`;
      }
      let [response1, response2] = await Promise.all([
        fetch(`${baseURL}appointments/schedule${queryAddition}`),
        fetch(`${baseURL}availability/getVisible?id=${THERAPIST_ID}`),
      ]);
      let [resolvedAppointments, allVisibleData] = await Promise.all([
        response1.json(),
        response2.json(),
      ]);

      let scheduleManager = new ScheduleManager();
      allVisibleData.forEach((data) => {
        // console.log(snakeToCamel(parseTSRANGE(data)));
        scheduleManager.addSchedule(snakeToCamel(parseTSRANGE(data)), []);
      });

      scheduleManager.mapBookedAppointmentsToSchedules(
        snakeToCamel(resolvedAppointments)
      );

      setTherapist(scheduleManager);
    };
    setupSchedule();
  }, [refresh]);

  return (
    <div className="page-layout__stacked">
      <NavBar callback={handlePrevPath}></NavBar>

      <div className="height-100 flex-column-direction">
        <CustomHero
          firstLine="Schedule Your"
          secondLine="Session"
          subheading="Find a moment that fits your schedule."
        ></CustomHero>
        {therapist ? (
          <>
            <CalendarForm
              therapist={therapist}
              handleRoute={handleAppointmentSelection}
              setRefresh={setRefresh}
              refresh={refresh}
            ></CalendarForm>

            {/* <button onClick={handleNavigation}>Go To Deposit</button> */}
          </>
        ) : (
          <>
            <CalendarStub></CalendarStub>
          </>
        )}
        {/* <div className="white-color" onClick={handleNavigation}>
        bypass
      </div> */}
      </div>
    </div>
  );
};

export default AppointmentDetails;
