// import leftArrow from '../../../assets/left-arrow.png';
import rightArrow from '../../../assets/right-arrow.png';
import {
  getMonthName,
  pstdayjs,
} from '../../../utils/calendar/calendarTools.js';

function CalendarHeader({ monthName, monthTracker, callback, selectedDay }) {
  let arrow;
  let alt = '';
  let offset;
  let otherMonth;
  let today = pstdayjs();
  let year;
  arrow = rightArrow;
  if (monthTracker === 0) {
    alt = 'Right arrow to check next months availability';
    offset = 1;
    otherMonth = getMonthName(today.add(1, 'M'));
    year = today.add(1, 'M').year();
  } else {
    alt = 'Left arrow to check previous months availability';
    offset = -1;
    otherMonth = getMonthName(today);
    year = today.year();
  }
  let dateString = '';
  if (selectedDay !== -1) dateString = selectedDay + ', ' + year;
  return (
    <div className="bottom-padding-xs calendar-header flex-row-direction flex-row-align-center ">
      <h3>
        {monthName} {dateString}
      </h3>
      <div
        className=" change-month flex-row-direction flex-row-align-center "
        onClick={(e) => {
          callback(offset);
          translationCSS(e.currentTarget);
        }}
      >
        <p className="normal-text-big unselective" unselectable="on">
          {otherMonth.slice(0, 3)}{' '}
        </p>
        <img src={arrow} alt={alt} className="unselective" unselectable="on" />
      </div>
    </div>
  );
}

/**
 *
 * @param {Event.currentTarget} e The target of the clicked on event
 */
const translationCSS = (e) => {
  e.children[0].classList.toggle('transform-header-p');
  e.children[1].classList.toggle('transform-header-img');
};
export default CalendarHeader;
